import React from "react";
import Layout from "../components/Layout";
import ResetPasswordForm from "../components/ResetPasswordForm";

const PasswordResetPage = (props) => {
	const infos = props.location.hash;
	return (
		<Layout>
			<div id="reset-password" className="min-vh-100">
				<div id="reset-password-content">
					<div className="container">
						<ResetPasswordForm infos={infos} />
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default PasswordResetPage;
