import React, { Component, Fragment } from 'react';
import axios from 'axios';
import SEO from '../components/SEO';

export default class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      confirmPassword: '',
      infos: props.infos,
      showSuccess: '',
      showError: '',
    };
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  validateForm = (password, confirmPassword) => {
    const reg = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
    if (!reg.test(password)) {
      this.setState({
        showError:
          'Please enter a password with at least 8 characters (lowercase and uppercase letters and numbers only, no punctuation or special characters)',
      });
    } else {
      if (password !== confirmPassword) {
        this.setState({ showError: 'Password Does Not Match' });
      } else {
        this.setState({ showError: '' });
        return password;
      }
    }
  };

  clearForm = () => {
    this.setState({ newPassword: '', confirmPassword: '' });
  };

  handleSubmit = event => {
    event.preventDefault();
    const np = this.state.newPassword;
    const cp = this.state.confirmPassword;
    const uid = this.state.infos.split('/')[1];
    const token = this.state.infos.split('/')[2];

    if (this.validateForm(np, cp) && uid && token) {
      axios
        .post('https://prod.api.gust.io/v1/auth/reset_password_confirm/', {
          uid: uid,
          token: token,
          new_password: np,
        })
        .then(response => {
          this.setState({
            showSuccess: 'Your password has been reset successfully',
          });
          this.clearForm();
        })
        .catch(error => {
          this.setState({
            showError:
              'Could not update your password, please check the reset link',
          });
        });
    }
  };

  showMessage = () => {
    const { showError, showSuccess } = this.state;
    if (showError) {
      return (
        <div className="alert alert-warning" role="alert">
          {showError}
        </div>
      );
    } else if (showSuccess) {
      return (
        <div className="alert alert-success" role="alert">
          {showSuccess}
        </div>
      );
    }
  };

  render() {
    return (
      <Fragment>
        <SEO title="Reset Your Password" />
        <div className="row text-white">
          <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto text-center form p-4">
            <div className="text-center logo-container">
              <img
                src={'https://assets.gust.io/logo_white.webp'}
                className="rounded"
                alt="logo"
              />
            </div>
            <h2 className="display-6 py-2 text-truncate">Reset Password</h2>
            <div className="px-2">
              <form
                onSubmit={this.handleSubmit}
                className="justify-content-center"
                id="form"
              >
                <div className="form-group form-group-align">
                  <label htmlFor="inputNewPassword">New password:</label>
                  <input
                    type="password"
                    name="newPassword"
                    value={this.state.newPassword}
                    onChange={this.handleInputChange}
                    className="form-control"
                    id="inputNewPassword"
                    title="Password should only contain letters and numbers with at least 8 characters. e.g. john123456"
                    required
                  />
                </div>
                <div className="form-group form-group-align">
                  <label htmlFor="inputConfirmPassword">
                    Confirm your password:
                  </label>
                  <input
                    type="password"
                    name="confirmPassword"
                    value={this.state.confirmPassword}
                    onChange={this.handleInputChange}
                    className="form-control"
                    id="inputConfirmPassword"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-outline btn-xl btn-update-password"
                >
                  Update Password
                </button>
                {this.showMessage()}
              </form>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
